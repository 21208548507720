import React from "react"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/youoptimize.scss"

import SEO from "../../components/seo"
import HeaderLogoImg from "../../assets/img/compsol/optimize_1.svg"
import HeaderImg from "../../assets/img/youOptimize/Img1.png"
import Icon1 from "../../assets/img/youOptimize/Icon1.png"
import Icon2 from "../../assets/img/youOptimize/Icon2.png"
import Icon3 from "../../assets/img/youOptimize/Icon3.png"
import Icon4 from "../../assets/img/youOptimize/Icon4.png"
import Icon5 from "../../assets/img/youOptimize/Icon5.png"
import Icon6 from "../../assets/img/youOptimize/Icon6.png"
import Icon7 from "../../assets/img/youOptimize/Icon7.png"
import Icon8 from "../../assets/img/youOptimize/Icon8.png"
import Icon9 from "../../assets/img/youOptimize/Icon9.png"
import Icon10 from "../../assets/img/youOptimize/Icon10.png"
import Icon11 from "../../assets/img/youOptimize/Icon11.png"
import Icon12 from "../../assets/img/youOptimize/Icon12.png"
import GroupImg1 from "../../assets/img/youOptimize/Img2.png"
import GroupImg2 from "../../assets/img/youOptimize/Img3.png"
import TickIcon from "../../assets/img/youOptimize/tick.svg"
import SingleForm from "../../components/singleForm"

const YouOptimizePage = () => {
  return (
    <div>
      <SEO
        title="Youoptimize"
        keywords={[`youpal`, `youpal group`, `youOptimize`]}
      />
      <div className="youOptimizeMain products">
        <section className="container">
          <div className="col">
            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                Inventory and Production Planning with Youoptimize
                </h2>
              </div>
            </div>
            <div className="d-block d-sm-none alignImage bugP"> 
              <img className="headerImg padImg" src={HeaderImg} />
            </div>
            <p className="internalNarrowCol">
            Our inventory planning solution is at your service to control your stocks, improve productivity and ensure on time delivery. Our production planning solution now makes it easy to manage your business’s team members, resources and inventory.
            </p>
            <Link to="/products/request-demo">
              <button
                type="button"
                className="btn cta btn-primary px-5 mt-5 productButton mx-auto d-block"
              >
                <span>Free demo</span>
              </button>
            </Link>
            <div className="hiddenImage d-none d-sm-block">
              <img className="headerImg" src={HeaderImg} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
            Never Act Without Planning. Not Anymore
            </h3>
            <p className="internalNarrowCol">
            Manage your supply chain successfully with these powerful tools:
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={Icon1} />
              </div>
              <h4>Stock monitoring</h4>
              <p>
              Youoptimize helps you calculate fixed safety stocks, but also minimize stockouts and overstocks.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon2} />
              </div>
              <h4>Reordering</h4>
              <p>
              Our analytics help you estimate reorder quantities. Get a clear view of when and how frequently you need to replenish your inventory.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon3} />
              </div>
              <h4>Accurate delivering</h4>
              <p>
              Youoptimize allows you to know your possible future capacity and forecast delivery dates accurately.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon4} />
              </div>
              <h4>Comparison</h4>
              <p>
              Test your plans and case scenarios with our supply chain management tool.
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg ordering" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left">
                  ANALYTICS
                </div>
                <h3 className="text-left">
                Automate Your Supply Chain Management
                </h3>
                <p>
                Inventory and production planning is effortless with the implementation of automation rules and tools.
                </p>
                <div className="row">
                  <div className="col">
                    <div className="squareHolder">
                      <img src={Icon5} />
                    </div>
                    <h4>Replenishment</h4>
                    <p>
                    Automatic orders based on the product, quantity, date, and location data.
                    </p>
                  </div>
                  <div className="col">
                    <div className="squareHolder">
                      <img src={Icon6} />
                    </div>
                    <h4>Transfer</h4>
                    <p>Automatically moving excess stock to another location.</p>
                  </div>
                </div>
                <div className="buttonArea">
                  <Link to="/products/request-demo">
                    <button type="button" className="btn btn-primary px-4">
                      <span>Try it out free</span>
                      <FaArrowRight style={{ fill: "white" }} />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">FEATURES</div>
              <h3>Benefit More Than You Invest</h3>
              <p>
              Youoptimize enhances planning and organisation and creates a transparent business environment.
              </p>
              <div className="row mt-5">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon7} />
                    </div>
                    <div className="col sideContent">
                      <h4>No stockouts and overstocks</h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon8} />
                    </div>
                    <div className="col sideContent">
                      <h4>Reorder at the right time</h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon9} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Reduce delays and optimise rush orders</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon10} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>
                          Separate inventory policies for different products
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon11} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>
                          Grow efficiency by cutting down wasted time on details
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon12} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Achieve higher productivity</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader">COLLABORATE</div>
                <h3>Stay in Control At Every Stage</h3>
                <p className="mt-5">
                Youoptimize provides a dashboard where your inventory and processes are centralised. You choose between seeing the minute details or the broader picture.
                </p>
                <div className="row collaborationList">
                  <div className="col-6">
                    <img src={TickIcon} />
                    Safety stock calculation
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Production scheduling
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Optimal reorder
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Constraining options
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Capacity planning
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YouOptimizePage
